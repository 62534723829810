import React from "react";
import style from "./mobile_bottommenu.module.css";
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from "react";
import { AuthContext } from '../context/AuthContext';
import { IoIosPerson, IoIosHome, IoIosAdd, IoIosChatboxes } from "react-icons/io";


const MobileBottomMenu = () => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);

  const handleHomePage = () => {
    navigate('/');
  }
  const handlePost = () => {
    navigate('/post');
  }
  const handleChat = () => {
    auth.userid ? navigate('/chat') : navigate('/login')
  };
  const handleProfile = () => {
    auth.userid ? navigate('/userpage') : navigate('/login')
  }

  return (
    <nav className={`${style.navBar} navbar fixed-bottom navbar-light bg-light`} style={{height: '50px'}}>
      <div className="container-fluid h-100">
        <div className="d-flex justify-content-around align-items-center w-100 h-100">
          <a className={style.individualButton} onClick={handleHomePage} style={{display: "flex", flexDirection: "column", alignItems: "center"}}> 
            <IoIosHome className="icon" style={{ fontSize: "1.25rem" }} /> 
            <span className="nav-link">Home</span>  
          </a>
          <a className={style.individualButton} onClick={handlePost} style={{display: "flex", flexDirection: "column", alignItems: "center"}}> 
            <IoIosAdd className="icon" style={{ fontSize: "1.25rem" }} />
            <span className="nav-link">Post</span>
          </a>
          <a className={style.individualButton} onClick={handleChat} style={{display: "flex", flexDirection: "column", alignItems: "center"}}> 
            <IoIosChatboxes className="icon" style={{ fontSize: "1.25rem" }} />
            <span className="nav-link">Chat</span>
          </a>
          <a className={style.individualButton} onClick={handleProfile} style={{display: "flex", flexDirection: "column", alignItems: "center"}}> 
            <IoIosPerson className="icon" style={{ fontSize: "1.25rem" }} /> 
            <span className="nav-link">Profile</span>
          </a>
        </div>
      </div>
    </nav>
  );
}

export default MobileBottomMenu;