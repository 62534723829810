import {Fragment} from "react";
import style from './Helpabout.module.css';


const Helpabout = props => {
    return(
      <Fragment>
        <div className = {style.container}>
            <h1>关于UmiUni</h1>
            <h1>UmiUni校友圈 是一款为北美校友们创办的校友网App。</h1>
            <p>校园生活，有你有我！</p>
            <p>UmiUni集二手交易、公寓转租、大学城周边餐馆信息、活动约饭、校友求职内推于一体。</p>
            <p>UmiUni北美校友网由各大美国大学校友团队团队联合创办，为广大在美大学生及校友服务。</p>
            <p>于2016年正式上线。</p>
            <h1>UmiUni团队现有团队成员</h1>
            <p>UIUC校友，NYU校友, Maryland校友，Purdue校友, CMU校友，UCLA校友等</p>
            <p>也有已在Facebook, Linkedin, Apple, Google, Uber的在职工程师校友的支持。</p>
            <h1>UmiUni支持的平台:</h1>
            <p>微信小程序，iOS App，网页版</p>
            <h1>UmiUni英才招募(software, business)：</h1>
            <p>请发送简历至admin@umiumi.com</p>
        </div>
      </Fragment>
    );
};

export default Helpabout;

