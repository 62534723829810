import { Fragment } from "react";
import classes from './Newhire.module.css';

const Newhire = props => {
    return(
      <Fragment>
        <div class="container">
            <h1>联系我们 - 申请加入团队</h1>
            <p>邮箱：admin@umiuni.com</p>
            <p>Software Positions微信：Charleszhu12321</p>
            <p>Business Positions微信：sirrir</p>
            <p>All Positions微信: tiantian20170427</p>
            <p>简历请发送至 admin@umiumi.com 校友团队会为合适的候选人安排面试。</p>
            <br></br>
            <h2><strong>简介</strong></h2>
            <p><u>Umi</u><u>Uni</u><u>.com</u> 于2016年创立，是服务广大北美在读/毕业校友的综合性社交平台。</p>
            <h3><strong>团队成员</strong></h3>
            <li>Apple、Facebook、Uber、Linkedin、Box 等公司的在职软件开发工程师</li>
            <li>CMU、NYU、UIUC、UCLA、Maryland、Stanford等校友</li>
            
            <h3><strong>目标用户</strong>	</h3>
            <li>NYU、Purdue、UIUC、UC-Davis 等知名院校的校友群体</li>
            <h3><strong>服务内容</strong></h3>
            <li>校友交流活动、求职招聘</li>
            <li>大学城周边餐馆信息、本地折扣</li>
            <li>二手闲置交易、房屋租赁</li>
            <h3><strong>产品平台</strong></h3>
            <li>微信小程序、iOS 应用、桌面网页</li>


            <br></br>
            <h2><strong>招聘</strong></h2>
            <p>美国各大院校的在读/毕业校友：</p>

            <br></br>
            <h3><strong>Software Engineers</strong></h3>
            <h3><blockquote>Minimum requirements:</blockquote></h3>
            <ol><li>Git, JavaScript</li>
            <li>Major in computer science, software engineering or related fields</li>
            <li>Full-time or internship experiences at U.S. tech companies</li></ol>
            <p><blockquote>Preferred requirements:</blockquote></p>
            <ol><li>Node.js, React,</li>
            <li>React-native, Android, Objective-C, Swift</li></ol>

            
            <br></br>
            <h3><strong>Business Operation Associates</strong></h3>
            <h3><blockquote>Minimum requirements:</blockquote></h3>
            <ol><li>Current college student or alumni graduated from a U.S. university</li>
            <li>Passionate about marketing, media and business operation</li>
            <li>Willing to engage in human resource management</li></ol>
            <p><blockquote>Preferred requirements:</blockquote></p>
            <ol><li>Startup experiences</li>
            <li>Full-time operation experiences</li></ol>
            <p><blockquote>Duties &amp; responsibilities:</blockquote></p>
            <ol><li>协助进行市场/用户调研</li>
            <li>协助市场推广计划的制定、实施和运营</li>
            <li>协助线上/线下营销方案的策划、组织和实施</li>
            <li>参与 business 团队的人才招募</li></ol>

            <br></br>
            <p>Software Positions微信：Charleszhu12321</p>
            <p>Business Positions微信：sirrir</p>
            <p>All Positions微信: tiantian20170427</p>
            <p>简历请发送至 admin@umiumi.com 校友团队会为合适的候选人安排面试。</p>
        </div>
      </Fragment>
    );
};

export default Newhire;
