import styles from './listChats.module.css';
import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const ListChats = () => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  // const users = [
  //   { id: "6877", name: "UmiUni_AI_GLM4", encodeid: "9OjoQeZV9vepZVg8" },
  //   { id: "6866", name: "Paper_AI", encodeid: "oYq9wdL8VgdjPXgE" },
  // ];

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',  // Adjust number of columns as necessary
    gridGap: '20px',  // Space between the grid items
    padding: '20px',  // Padding around the grid
  };

  // const chatBoxContainerStyle = {
  //   backgroundColor: '#f0f0f0',  // Light background color for each box
  //   border: '2px solid #ccc',  // Border for each box
  //   padding: '10px',  // Padding inside each box
  //   textAlign: 'center',  // Centering text and button
  // };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',  // Space between the buttons
    marginTop: '16px',
  };
  const buttonStyle = {
    fontSize: '16px',
    padding: '0',  // Reset padding to 0
    backgroundColor: 'orange',
    color: 'white',
    borderRadius: '5px',
    width: '100px',  // Set fixed width
    height: '100px',  // Set fixed height to make it square
    display: 'flex',  // Use flexbox for centering text
    justifyContent: 'center',  // Center text horizontally
    alignItems: 'center',  // Center text vertically
    cursor: 'pointer',  // Cursor pointer to indicate clickable
    transition: 'background-color 0.3s',  // Smooth background color transition
    marginTop: '10px',  // Space between buttons
};



  return (
    <div>
      <div className={styles.headerContainer}>
        <h1 className={styles.header}>
          Chats
        </h1>
      </div >
      {/* <div className={styles.chatsContainer}>
        {chats.map((chat, index) => (
          <ChatEntry key={index} chat={chat}/>
        ))}
      </div> */}
      <div style={buttonContainerStyle}>
        {/* <button
          style={buttonStyle}
          onClick={() => navigate('/chat/ai')}
        >
          AI Chatroom
        </button>
        <button
          style={buttonStyle}
          onClick={() => navigate('/chat/individual')}
        >
          Individual Chatroom
        </button> */}
        <button
          style={buttonStyle}
          onClick={() => navigate('../globalchatroom')}
        >
          Global Chatroom
        </button>
      </div>
    </div>
  );
};

export default ListChats;
