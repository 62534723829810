import axios from "axios";
import { useState, useEffect } from "react";
import ItemCard from "../item_cards/Item_card";
import CardGroup from "react-bootstrap/CardGroup";
import { useParams, useNavigate } from "react-router-dom";
import style from "./search.module.css";

const Search = () => {
  const [returnDataItems, setReturnDataItems] = useState([]);
  const [returnDataItemsUrl, setReturnDataItemsUrl] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);

  // Extract parameters from the URL based on the new route pattern
  let { name, type, offset } = useParams();

  // Debugging logs to verify parameter values
  console.log("Parameters:", { name, type, offset });

  // Ensure offset is parsed as a number
  offset = isNaN(parseInt(offset)) ? 0 : parseInt(offset, 10);

  const navigate = useNavigate();

  // Update the route path to match the new URL pattern
  const updateRoutePath = (newName, newType, newOffset) => {
    navigate(`/search/${newName || ''}/${newType}/${newOffset.toString()}`);
  };

  // Update pageOffset when URL offset changes
  useEffect(() => {
    setPageOffset(offset);
  }, [offset]);

  useEffect(() => {
    // Form the URL with the 'name' parameter (it can be empty).
    let apiUrl = `${process.env.REACT_APP_API_URL}api/item/list?limit=30&offset=${pageOffset}&school_id=1&sort=time_desc&type=${type}`;
    
    // Only append the name parameter if it's not empty
    if (name && name.trim() !== '') {
      apiUrl += `&name=${encodeURIComponent(name)}`;
    }

    console.log("API URL:", apiUrl); // Debugging log to see the final API URL

    const config = {
      method: "get",
      url: apiUrl,
      headers: {
        "Content-Type": "application/json",
      },
    };

    async function getDataItems() {
      try {
        const response = await axios(config);
        setReturnDataItems(response.data.items);

        // Create an array of image URLs
        const dataItems = response.data.items.map(item => `https://oss.umiuni.com/${item.photo}`);
        setReturnDataItemsUrl(dataItems);
      } catch (error) {
        console.log(error);
      }
    }

    getDataItems();
  }, [pageOffset, type, name]); // Trigger the effect when any of these values change

  const detailNavigation = (iid) => {
    return `/detail/${iid}`;
  };

  const cardGroup = () => {
    if (returnDataItems) {
      return returnDataItems.map((currentItem, i) => (
        <ItemCard
          key={currentItem.iid}
          item={currentItem}
          imageUrl={returnDataItemsUrl[i]}
          onClick={() => (window.location.href = detailNavigation(currentItem.iid))}
        />
      ));
    } else {
      return null;
    }
  };

  const changeOffset = (change) => {
    const newOffset = pageOffset + change * 30;

    if (newOffset < 0) {
      setPageOffset(0);
    } else {
      setPageOffset(newOffset);
    }

    // Update the URL for changing offset with 'name' parameter included
    updateRoutePath(name || '', type, newOffset);
  };

  return (
    <>
      <div className={style.background}>
        <div className={style.searchContainer}>
          <a href="javascript:history.back()">Back</a>
          <CardGroup>{cardGroup()}</CardGroup>
        </div>
        <div className={style.buttonContainer}>
          <button className={style.buttonLeft} onClick={() => changeOffset(-1)}>
            Last Page
          </button>
          <button className={style.buttonRight} onClick={() => changeOffset(1)}>
            Next Page
          </button>
        </div>
      </div>
    </>
  );
};

export default Search;

