import React, { useState, useEffect } from "react";
import style from "./content_sharing.module.css";
import axios from "axios";
import { IoIosArrowBack, IoIosArrowForward, IoIosClose } from 'react-icons/io';

const ContentSharing = () => {
    const [contentList, setContentList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);  
    const [userId, setUserId] = useState([]);

    useEffect(() => {
        const fetchUserIds = async () => {
            try {
                const { data } = await axios.get('https://python.umiuni.com/umiuni_content_get_user/?pwd=testing');
                setUserId(data.userid);
            } catch (error) {
                console.error(error);
            }
        };

        fetchUserIds();
    }, []);

    useEffect(() => {
        const fetchContent = async () => {
            if (!selectedUserId) {
                setContentList([]);
                setCurrentIndex(null);
                return;
            }
            try {
                const { data } = await axios.get(`https://python.umiuni.com/umiuni_content_getting_self/${selectedUserId}/?pwd=testing`);
                setContentList(data);
                setCurrentIndex(data.length > 0 ? Math.floor(Math.random() * data.length) : null);
            } catch (error) {
                console.error(error);
            }
        };

        fetchContent();
    }, [selectedUserId]);

    const changeContent = () => {
        let i = Math.floor(Math.random() * contentList.length);
        while (i === currentIndex) {
            i = Math.floor(Math.random() * contentList.length);
        }
        setCurrentIndex(i);
    };

    const createCards = () => {
        if (contentList.length === 0 || currentIndex === null) return null;
        
        const item = contentList[currentIndex];
        return (
            <div className={style.contentWrapper} key={currentIndex}>
                <div className={style.content}>
                    <button className={style.leftButton} onClick={changeContent}><IoIosArrowBack /></button>
                    {item.photo_location.endsWith('.pdf') ? (
                        <a href={item.photo_location}>{item.content_name}</a>
                    ) : (
                        <img src={item.photo_location} alt={item.content_name} />
                    )}
                    <button className={style.rightButton} onClick={changeContent}><IoIosArrowForward /></button>
                </div>
                <button className={style.closeButton} onClick={() => setSelectedUserId(null)}><IoIosClose /></button>
            </div>
        );
    };

    return (
        <div className={style.contentCards}>
            {createCards()}
            <div className={style.switchUser}>
                {userId.map((id, index) => 
                    <div key={index}>
                        <button className={style.switchUserContent} onClick={() => setSelectedUserId(id)}>Username,<br />{id}</button>
                    </div>  
                )}
            </div>
        </div>
    );
};

export default ContentSharing;
