import React from "react";
import style from "./header_schooldropdown.module.css";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const HeaderSchoolDropdown = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [dpIsOpen, setDpIsOpen] = useState(false);
    const [hotSchools, setHotSchools] = useState([]);
    const [currentSchool, setCurrentSchool] = useState(null);
    const dropdownRef = useRef(null);
    
    const toggleDropdown = () => {
        console.log("incite dropdown");
        setIsOpen(!isOpen);
    };
    const togglePopup = () => {
        console.log("incite popup");
        setDpIsOpen(!dpIsOpen);
    };
    const defaultSchool = () => {
        console.log("incite default school");
        localStorage.removeItem("savedSchool");
        setCurrentSchool(hotSchools[0])
        window.location.href = "/";
    }
    const handleSchoolClick = (schoolid, schoolname, schoolabbr) => {
        console.log("incite handle school click");
        setCurrentSchool({ id: schoolid, name: schoolname, abbr: schoolabbr });
        setDpIsOpen(false);
        localStorage.setItem("savedSchool", JSON.stringify({ id: schoolid, name: schoolname, abbr: schoolabbr }));
    }
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
    }

    useEffect(() => {
        const fetchHotSchools = async () => {
          try {
            const response = await fetch("https://umiuni.com/api/school/list");
            if (!response.ok) {
              throw new Error("failed");
            }
            const data = await response.json();
            const hotSchoolsData = Object.values(data).flatMap(schools => schools.filter(school => school.hot > 0));
            setHotSchools(hotSchoolsData);

            const storedSchool = JSON.parse(localStorage.getItem("savedSchool"));
            if (storedSchool) {
                setCurrentSchool(storedSchool);
            }
            else {
                setCurrentSchool(hotSchoolsData[0]);
            }
          } catch (error) {
            console.error("error", error);           
          }
        };
        fetchHotSchools();

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);


      return (
        <div ref={dropdownRef}>   
            <button onClick={toggleDropdown} className={style.schooldropdown}>School: {currentSchool ? currentSchool.abbr : ""}
                <div className={isOpen ? style.arrowUp : style.arrowDown}>⌄</div>
            </button>
                {isOpen && (
                    <div className={style.dropdowncontent}>
                        <div className={style.dropdown}>
                            <div className={style.message}>Current School: {currentSchool ? currentSchool.name : ""}</div>
                            <button onClick={togglePopup}>Change School</button> 
                            <button onClick={defaultSchool} style={{marginTop: "10px"}}>Default School</button>
                        </div>
                    </div>
                )}

                {isOpen && dpIsOpen && (
                    <div className={style.popup}>
                        <div className={style.popupContent} style={{maxHeight: "400px", overflowY: "auto", maxWidth: "600px"}}>
                            <h2>Choose a School</h2>
                            <ul>
                                {hotSchools.map(school => (
                                <li key={school.id}>
                                    <Link to={`/school/${school.id}`} onClick={() => handleSchoolClick(school.id, school.name, school.abbr)}>
                                        <button>{school.name}</button>
                                    </Link>
                                </li>
                                ))}
                            </ul>
                        </div>                     
                </div>)}
        </div>
    );
}

export default HeaderSchoolDropdown;