import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import SendChat from "./components/chat/SendChat";
import ListChats from "./components/chat/ListChats";
import GlobalChatRoom from "./components/chat/GlobalChatRoom";

import Contactus from "./components/footer/UmiUni_Team/Contactus";
import PrivacyPolicy from "./components/footer/UmiUni_Team/PrivacyPolicy";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import logo from "./doggy_coding_icon.svg";
import Layout from "./layout";
import Login from "./components/login/Login";
import VerifyEmail from "./components/register/verify_email";
import SignUp from "./components/register/sign_up";
import Search from "./components/search/Search";
import Helpabout from "./components/footer/UmiUni_Team/Helpabout";
import Newhire from "./components/footer/UmiUni_Team/Newhire";
import Logout from "./components/logout/Logout";
import Detail from "./components/detail/Detail";
import Forum from "./components/forum/Forum";
import ForumList from './components/forumlist/ForumList';
import PasswordReset from "./components/password_reset/Password_reset";
import FAQ from "./components/faq/FAQ";
import UserPage from "./components/user_page/User_page";
import UserProfile from "./components/user_page/User_profile";
import UserChangePassword from "./components/user_page/User_change_password";
import UserManage from "./components/user_page/User_manage";
import { Fragment, useState, useEffect, useContext } from "react";
import "./App.css";
import Upload from "./components/upload/Upload";
import Post from "./components/post/Post";
import ForumDetail from "./components/forumdetail/ForumDetail"
import CancelPage from "./components/donate/canclePage/CancelPage";
import SuccessPage from "./components/donate/successPage/SuccessPage";
import ProductDetails from "./components/donate/productDetails/ProductDetails";
import TopUp from "./components/topup/TopUp";
// import TopUpSuccess from "./components/topup/TopUpSuccessPage";
// import TopUpFail from "./components/topup/TopUpFailPage";
import MobileBottomMenu from "./components/mobile_bottommenu/mobile_bottommenu";
import ContentSharing from "./components/content_sharing/content_sharing";
import ContentSharingUpload from "./components/content_sharing/content_sharing_upload";
import AIChat from "./components/chat/ai";
import IndividualChat from "./components/chat/individual";
import { AuthContext } from "./components/context/AuthContext";
import { set } from "react-ga";

const RenderFooter = () => {
  const location = useLocation();
  const excludePaths = ['/userpage', '/chat'];
  const shouldRenderFooter = !excludePaths.includes(location.pathname);
  return shouldRenderFooter ? <Footer /> : null;
}

function App() {
  const axios = require('axios');
  const { auth, logout } = useContext(AuthContext);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if(auth.session){
    console.log("auth.session exists in App.js", "auth.session:", auth.session);
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}api/user/check_session`,
      params: {
        "userid": auth.userid
      }
    };

    axios.request(config)
    .then((response) => {
      console.log("In App.js, checking session:","auth.session:", auth.session);
      const sessions = response.data.sessions || [];
      const isSessionValid = response.data.sessions && response.data.sessions.some(session => {
        return session === auth.session;
      });
      if (!isSessionValid || (response.data.error && response.data.error.includes("Session not found"))) {
        console.log ("In App.js, session is not valid or response data has error and response data has session not found message, going to logout");
        logout();
      }
    })
    .catch((error) => {
      console.log(error);
    });
    }else{
      console.log("auth.session not exists in App.js");
    }
  }, []);
  
  useEffect(() => {
      const checkMobile = () => {
          setIsMobile(window.innerWidth <= 768);
      };
      checkMobile();
      window.addEventListener("resize", checkMobile);
      return () => {
        window.removeEventListener("resize", checkMobile);
      };
  }, [])
  
    return (
    <>
      <Fragment>
        <BrowserRouter>
        <div style={{ paddingBottom: isMobile ? '50px' : 0 }}>
          <Header />
          <Routes>
            <Route exact path="/" element={<Layout />} />
            <Route exact path="/verifyemail" element={<VerifyEmail />} />
            <Route exact path="/helpabout" element={<Helpabout />} />
            <Route exact path="/contact-us" element={<Contactus />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/newhire" element={<Newhire />} />
            <Route exact path="/passwordreset" element={<PasswordReset />} />
            <Route exact path="/userpage" element={<UserPage />} />
            <Route path="/signup">
              <Route
                path="/signup/:email1/:verification_code1"
                element={<SignUp />}
              />
            </Route>
            <Route exact path="/login" element={<Login />} /> {/* This should come before the nested routes */}
      	    <Route path = "/login">
      	      <Route path="/login/:userid/:session" element={<Login />} />
      	    </Route>
            <Route path="/search">
              <Route path="/search/:name?/:type/:offset/" element={<Search />} />
            </Route>
            <Route path="/detail">
              <Route path="/detail/:iid" element={<Detail />} />
            </Route>
            <Route path = "/school/:schoolId" element = {<Layout />} />
            <Route path="/forum">
              <Route path="/forum/list" element={<Forum />} />
            </Route>
      	    <Route path = "/forumlist">
      	      <Route path="/forumlist/:topic/:schoolId" element={<ForumList />} />
      	    </Route>
	    <Route path="/chats" element={<ListChats />} /> {/* This should come before the nested routes */}
      	    <Route path = "/chat">
      	      {/* <Route path="/chat/:to_userid" element={<SendChat />} /> */}
              <Route exact path="/chat/ai" element={<AIChat />} />
              
              <Route exact path="/chat/individual"element={<IndividualChat />}/>
              <Route path="/chat/:to_userid" element={<SendChat />} />
              {/* </Route>  */}
              {/* <Route exact path="/chat/individual"  > */}

      	    </Route>
            <Route path="/globalchatroom" element={<GlobalChatRoom />} /> {/* This should come before the nested routes */}
            <Route path="/faq" element={<FAQ />} />
            <Route>
              <Route path="/content_sharing/" element={<ContentSharing />} />
              <Route path="/content_sharing/upload" element={<ContentSharingUpload />} />
            </Route>
            {/* temporary route for upload feature */}
            <Route path="/post" element={<Post />} />

            <Route path = "/userpage" element = {<UserPage />}>
              <Route path = "/userpage/profile" element = {<UserProfile />} />
              <Route path = "/userpage/password" element = {<UserChangePassword />} />
              <Route path = "/userpage/manage" element = {<UserManage />} />
            </Route>

            <Route path = "/logout" element = {<Logout />} />
            <Route path = "/forumdetail">
              <Route path = "/forumdetail/:ftid" element = {<ForumDetail />} />              
            </Route>
            <Route path="/post" element={<Post />} />
            
            <Route path="/upload" element={<Upload />} />

            {/* Donate */}
            <Route path="/donate/cancel" element={<CancelPage />} />
            <Route path="/donate/success" element={<SuccessPage />} />
            <Route path="/donate/payment" element={<ProductDetails />} />

            {/* Top Up*/}
            <Route path="/topup" element={<TopUp />} />

          </Routes>
          {<RenderFooter />}
          </div>
          {isMobile && <MobileBottomMenu />}
        </BrowserRouter>
      </Fragment>
    </>
  );
}

export default App;
