import React, { useState, useRef, useEffect } from "react";
import style from "./header_searchbar.module.css";
import { useNavigate } from "react-router-dom";

const HeaderSearchbar = () => {
  const [selectedCategory, setSelectedCategory] = useState("items");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const categories = [
    { value: "items", label: "Items" },
    { value: "rentals", label: "Rentals" },
    { value: "vehicles", label: "Vehicles" },
    { value: "events", label: "Events" },
    { value: "shops", label: "Shops" },
  ];

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const categoryTranslate = (category) => {
    switch(category) {
      case "items":
        return "used";
      case "rentals":
        return "house";
      case "vehicles":
        return "car";
      case "events":
        return "event";
      case "shops":
        return "merchant_house";
      default:
        return "items";
    }
  };


  const handleSearch = () => {
  let category = categoryTranslate(selectedCategory);
  let offset = 0; // Ensure offset is initially set to 0
  let name = inputValue.trim(); // Remove extra spaces

  // Redirect to the appropriate URL based on whether the input is empty
  let url = name ? `/search/${encodeURIComponent(name)}/${category}/${offset}` : `/search/${category}/${offset}`;
  
  // Handle the case where the URL should not have a double slash
  if (!name) {
    url = `/search/${category}/${offset}`;
  }
  
  navigate(url);
  };


  const handleInput = (e) => {
    setInputValue(e.target.value);  // Updates inputValue state with the typed value
  };

  const selectedCategoryObject =
    categories.find((category) => category.value === selectedCategory) ||
    categories[0];

  return (
    <div className={style.searchbar}>
      <input
        type="text"
        placeholder="Search for"
        spellCheck="false"
        className={style.input}
        value={inputValue}
        onChange={handleInput} // Updates inputValue when typing
      />
      <div className={style.customDropdown} ref={dropdownRef}>
        <div className={style.dropdownHeader} onClick={toggleDropdown}>
          <div className={style.categoryContainer}>
            {selectedCategoryObject.label}
          </div>
          <div className={isDropdownOpen ? style.arrowUp : style.arrowDown}>
            ⌄
          </div>
        </div>
        {isDropdownOpen && (
          <ul className={style.dropdownOptions}>
            {categories.map((category) => (
              <li
                key={category.value}
                onClick={() => handleCategoryChange(category.value)}
                className={style.dropdownOption}
              >
                {category.label}
              </li>
            ))}
          </ul>
        )}
      </div>
      <button className={style.searchButton} onClick={handleSearch}>Search</button>
    </div>
  );
};

export default HeaderSearchbar;

